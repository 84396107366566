<template>
  <div class="view pa24">
    <el-table :data="tableData" border>
      <el-table-column type="index" width="50" align="center"></el-table-column>
      <el-table-column prop="describes" label="标题" width="180" align="left" header-align="left"></el-table-column>
      <el-table-column prop="url" label="链接" align="left" header-align="left"></el-table-column>
      <el-table-column width="150" align="center">
        <template slot="header" slot-scope="scope">
          <el-button @click="handleAdd(scope.row)" type="primary" size="small">新增</el-button>
        </template>
        <template slot-scope="scope">
          <el-button @click="handleShow(scope.row)" type="text" size="small">查看</el-button>
          <el-button type="text" size="small">
            <el-popconfirm title="确定要删除该内容吗？" @confirm="handleDelete(scope.row)">
              <el-button slot="reference" type="text" size="small">删除</el-button>
            </el-popconfirm>
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
        :title="dialogVideoTitle"
        :visible.sync="dialogViewVisible"
        :modal-append-to-body="false"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
      <video width="100%" :src="dialogVideoUrl" controls="controls"/>
    </el-dialog>

    <el-dialog
        title="新增企业视频"
        :visible.sync="dialogUploadVisible"
        :modal-append-to-body="false"
        :destroy-on-close="true">
      <el-form :model="ruleForm" label-width="auto">

        <el-form-item label="视频标题">
          <el-input v-model="ruleForm.describes" placeholder="请输入视频标题"></el-input>
        </el-form-item>

        <el-form-item label="视频封面图">
          <el-upload
              :action="uploadUrl"
              :class="{ disabled: uploadDisabled }"
              list-type="picture-card"
              :on-progress="handCoverProgress"
              :on-success="handleCoverSuccess"
              :on-error="isLoadingCover = false"
              :limit="1"
              :file-list="fileList"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div slot="tip" class="el-upload__tip">
              上传图片的最佳尺寸：750像素*750像素，其他尺寸会影响页面效果，格式png，jpeg，jpg。大小不超过2M。
            </div>
          </el-upload>
        </el-form-item>

        <el-form-item label="企业视频">
          <el-upload
              :action="uploadUrl"
              :class="{ disabled: uploadDisabled }"
              :on-progress="handProgress"
              :on-success="handleSuccess"
              :on-error="isLoading = false"
              :limit="6"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div slot="tip" class="el-upload__tip">
              只能上传MP4文件，且不超过10M。
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="saveForm()">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {insertCompanyVideo, selectCompanyVideo, delCompanyVideo} from "@/api/client";

/** xwltz  */
export default {
  props: {
    company: {},
  },
  data() {
    return {
      ruleForm: {
        companyId: 0,
        describes: '',
        cover: '',
        url: '',
        sort: 0
      },
      fileList: [],
      tableData: [],
      dialogViewVisible: false,
      dialogUploadVisible: false,
      dialogVideoTitle: '',
      dialogVideoUrl: '',
      uploadUrl: this.$store.state.uploadingUrl,
      ossUrl: this.$store.state.ossUrl,
      isLoading: false,
      isLoadingCover: false
    };
  },
  computed: {
    uploadDisabled() {
      return this.fileList.length >= 1;
    }
  },
  created() {},
  methods: {
    getCompanyVideo() {
      let data = {
        companyId: this.ruleForm.companyId
      }
      selectCompanyVideo(data).then(res => {
        this.tableData = res.data
      }).catch(err => {
        this.tableData = [];
      })
    },
    handCoverProgress(event, file, fileList) {
      if (this.isLoadingCover) return
      this.isLoadingCover = true
      this.$message({message: "正在上传"});
    },
    handleCoverSuccess(response, file, fileList) {
      debugger
      this.$message.closeAll();
      this.isLoadingCover = false
      this.$message({message: "上传成功", type: "success"});
      // todo 重新刷新表格
      this.fileList = fileList;
      this.ruleForm.cover = response.data.indexOf("https") >= 0 ? response.data : this.ossUrl + response.data
    },
    handProgress(event, file, fileList) {
      if (this.isLoading) return
      this.isLoading = true
      this.$message({message: "正在上传"});
    },
    handleSuccess(response, file, fileList) {
      debugger
      this.$message.closeAll();
      this.isLoading = false
      this.$message({message: "上传成功", type: "success"});
      // todo 重新刷新表格
      this.ruleForm.url = response.data.indexOf("https") >= 0 ? response.data : this.ossUrl + response.data
    },
    saveForm() {
      insertCompanyVideo(this.ruleForm).then(res => {
        if (res.code === 200) {
          this.$message({message: "保存成功", type: "success",});
          this.dialogUploadVisible = false
          this.getCompanyVideo()
        }

        this.fileList = []
        this.ruleForm.cover = ''
        this.ruleForm.sort = 0
        this.ruleForm.url = ''
        this.ruleForm.describes = ''
      })
    },
    handleAdd() {
      this.dialogUploadVisible = true
    },
    handleShow(row) {
      this.dialogVideoUrl = row.url;
      this.dialogVideoTitle = row.describes;
      this.dialogViewVisible = true;
    },
    handleDelete(row) {
      delCompanyVideo({videoId: row.videoId}).then(res => {
        if (res.code === 200) {
          this.getCompanyVideo()
        }
        this.$message(res.message);
      }).catch(error => {
        this.$message.error(error);
      })
    }
  },
  mounted() {
    this.ruleForm.companyId = this.company.companyId
    this.getCompanyVideo()
  }
};
</script>

<style lang="scss" scoped>
/deep/ .disabled .el-upload--picture-card {
  display: none;
}
</style>